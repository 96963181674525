import React, {useContext} from 'react';
import { motion } from 'framer-motion';
import { styles } from '../styles';
import { fadeIn, textVariant } from "../utils/motion";
import { SectionWrapper } from '../hoc'
import TranslucentEyes from "./TranslucentEyes";

import {ColorContext} from "./ColorContext";

const About = () => {
  const { colorHex, setColorHex } = useContext(ColorContext);

  return (
      <>
          <div className="bg-black-100 rounded-[20px] -mt-10 flex">

              <div className={`${styles.padding} bg-tertiary rounded-2xl border-0 min-h-screen`}>
                  <motion.div variants={textVariant()} className="">
                      <p className={`${styles.sectionSubText} jcblue-text`}>Introduction</p>
                      <h2 className={styles.sectionHeadText}>Overview</h2>
                  </motion.div>

                  {/* Default translucent eyes with default zIndex */}
                  {/*<TranslucentEyes displayColor={colorHex} opacity={0.5} />*/}

                  {/*/!* Custom translucent eyes with a higher zIndex *!/*/}
                  {/*<TranslucentEyes*/}
                  {/*    displayColor="#00ff00"*/}
                  {/*    position={{ x: 300, y: 200 }}*/}
                  {/*    scale={0.5}*/}
                  {/*    rotation={20}*/}
                  {/*    opacity={0.5}*/}
                  {/*    glowIntensity={200}*/}
                  {/*    zIndex={20} // Custom zIndex*/}
                  {/*/>*/}

                  {/*/!* Another set of translucent eyes with a lower zIndex *!/*/}
                  <TranslucentEyes
                      // displayColor={colorHex}
                      displayColor="#39FF14"
                      position={{ x: -350, y: 400 }}
                      rotation={-10}
                      opacity={0.5}
                      zIndex={5} // Lower zIndex
                      flip={true}
                  />
                  <motion.p
                      variants={fadeIn("", "", 0.1, 1)}
                      className="mt-4 text-secondary text-[17px] max-w-6xl leading-[30px] flex"
                  >
      <span>  I am a father, husband, technologist, and lifelong tinkerer. Originally from Tallahassee, Florida, I have a deep love for the outdoors, BBQ (especially Sonny’s!), the beach, and cheering for the Seminoles. Today, I call Cincinnati, Ohio home, where I live with my wife, Kim, our three daughters—Joelle, Kaelyn, and Lori—and our two pups, Lola and Snoopy.
          {/*<p className="text-[17px] text-secondary w-full mt-5">*/}
          {/*    */}
          {/*</p>*/}<br/><br/>
          <span className="text-[17px] text-secondary w-full mt-5">
              My journey in technology began early, programming on a Commodore 64, and evolved into a career spanning nearly three decades. Starting as a PC and network technician in 1995, I’ve grown into leadership roles, driving innovation and delivering impactful solutions for organizations like Globalization Partners, Glassdoor, Ubisoft, Cengage Learning, and Cornerstone Brands.
          </span>
          <br/><br/>
          <span className="text-[17px] text-secondary w-full mt-5">
              In my professional life, I am a highly technical leader; concentrated primarily in the web, data and analytics spaces.  I have extensive experience managing complex initiatives aimed at delivering meaningful data driven insights, automated processes, and supportable platforms.
           </span>
          <br/><br/>
          <span className="text-[17px] text-secondary w-full mt-5">
              Currently, I serve as Manager of AI Engineering at Globalization Partners, where I lead cross-functional teams to develop innovative AI-driven solutions that solve complex global challenges. My professional expertise lies in web development, data, and analytics, with extensive experience managing complex initiatives that deliver data-driven insights, automate processes, and build scalable, supportable platforms.
          </span>
          <br/><br/>
          <span className="text-[17px] text-secondary w-full mt-5">
              In my personal life; I enjoy spending time with my family, working in the yard, traveling, and embracing life’s journey
          </span>
          {/*<p className="text-center mt-12 pt-10">Please have a look around at some of my projects and interests.</p>*/}
      </span>

                  </motion.p>
                  <div
                      className="cursor-pointer w-full flex justify-center items-center ">
                      <a href="#work" className="cursor-pointer about_chevron_container">
                          <div className="about_chevron_container cursor-pointer ">
                              <div className="about_chevron cursor-pointer"></div>
                              <div className="about_chevron cursor-pointer"></div>
                              <div className="about_chevron cursor-pointer"></div>
                          </div>
                      </a>
                  </div>
                  <style>
                      {`
                    .about_chevron:before,
                    .about_chevron:after {
                        border-left: .01em solid #b452ce';
                        border-bottom:  .5px solid #b452ce;
                        border-top: .5px solid #b452ce;
                        border-right: .01em solid #b452ce;
                        background: #b452ce;
                    }`}
                  </style>
              </div>
          </div>

      </>
  )
}

export default SectionWrapper(About, "about")
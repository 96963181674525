import * as THREE from 'three'
import React, { Suspense, useRef, useEffect } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { EffectComposer, DepthOfField } from '@react-three/postprocessing'
import {isMobile} from 'react-device-detect';

function Galaxy({ dof, innerColor }) {
    const parameters = {
        count: 80000,
        size: 0.01,
        radius: 5,
        branches: 6,
        spin: 0.95,
        randomness: 0.3,
        randomnessPower: 3,
        // insideColor: '#818aa7',
        // outsideColor: '#ae8ea5',
        // thirdColor: '#fff',
        // insideColor: '#a89599', // 4A80FF
        insideColor: new THREE.Color(parseInt(innerColor.replace("#", "0x"), 16)),
        outsideColor: '#aaafd5',
        thirdColor: '#aafeff',
        mouse: false,
        animate: true,
        opacity: 0,
        focusDistance: 0.05,
        focalLength: 0.05,
        width: 480,
        height: 480,
        focusX: 1,
        focusY: 1,
        focusZ: 1,
        bokehscale: 1
    }

    const particles = useRef()
    // const [temp] = useState(() => new THREE.Vector3())
    // const [focus] = useState(() => new THREE.Vector3())

    useEffect(() => {
        generateGalaxy()
    }, [innerColor])

    useFrame((state, delta) => {
        if (dof.current) {
            dof.current.circleOfConfusionMaterial.uniforms.focusDistance.value = parameters.focusDistance
            dof.current.circleOfConfusionMaterial.uniforms.focalLength.value = parameters.focalLength
            dof.current.resolution.height = parameters.height
            dof.current.resolution.width = parameters.width
            dof.current.target = new THREE.Vector3(parameters.focusX, parameters.focusY, parameters.focusZ)
            dof.current.blendMode.opacity.value = parameters.opacity
        }

        // TODO use delta instead
        if (!isMobile) {
            if (parameters.animate) {
                const elapsedTime = state.clock.getElapsedTime()
                particles.current.rotation.y = 0.05 * elapsedTime
            }
        }
    })

    const generateGalaxy = () => {
        const positions = new Float32Array(parameters.count * 3)
        const colors = new Float32Array(parameters.count * 3)
        const colorInside = new THREE.Color(parameters.insideColor)
        const colorOutside = new THREE.Color(parameters.outsideColor)
        const thirdColor = new THREE.Color(parameters.thirdColor)

        for (let i = 0; i < parameters.count; i++) {
            const i3 = i * 3

            const radius = Math.random() * parameters.radius
            const spinAngle = radius * parameters.spin
            const branchAngle = ((i % parameters.branches) / parameters.branches) * Math.PI * 2

            const randomX = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : -1) * parameters.randomness * radius
            const randomY = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : -1) * parameters.randomness * radius
            const randomZ = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : -1) * parameters.randomness * radius

            positions[i3] = Math.cos(branchAngle + spinAngle) * radius + randomX
            positions[i3 + 1] = randomY
            positions[i3 + 2] = Math.sin(branchAngle + spinAngle) * radius + randomZ

            const mixedColor = colorInside.clone()
            mixedColor.lerp(colorOutside, radius / parameters.radius)

            colors[i3] = mixedColor.r
            colors[i3 + 1] = mixedColor.g
            colors[i3 + 2] = mixedColor.b
            colors[i3 + 3] = thirdColor
        }

        particles.current.geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
        particles.current.geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))
    }

    return (
        <points ref={particles}>
            <bufferGeometry />
            <pointsMaterial size={parameters.size} sizeAttenuation={true} depthWrite={true} vertexColors={true} blending={THREE.AdditiveBlending} />
        </points>
    )
}

function Nucleus({ size }) {
    const nucleusRef = useRef()
    const color = new THREE.Color()
    color.setHSL(Math.random(), 0.7, Math.random() * 0.2 + 0.05)

    return (
        <mesh ref={nucleusRef} position={[0, 0, 0]} scale={[size, size, size]} >
            <sphereBufferGeometry attach="geometry" args={[0.5, 32, 32, 0, 6.4, 0, 6.3]} />
            <meshBasicMaterial attach="material" color={'#fff'} />
        </mesh>
    )
}

const Effects = React.forwardRef((props, ref) => {

    const { bokehScale } = {
        min: 0,
        max: 10,
        value: 1,
    }
    return (
        <EffectComposer multisampling={0}>
            <DepthOfField ref={ref} bokehScale={bokehScale} />
        </EffectComposer>
    )
})

const GalaxyCanvas = (args) => {
    const dof = useRef()
        return (
            <Canvas className="pt-0 pl-0 pb-12 overflow-hidden" linear flat camera={{ position: [0, 2, 5] }} >
                <Suspense fallback={null}>
                    <Galaxy dof={dof} innerColor={`${args.displayColor}`}/>
                    <Nucleus size={0.125} />
                    {/*<BlackHoleNucleus size={0.385}/>*/}
                </Suspense>
                <Effects ref={dof} />
                {/*<axesHelper args={[2, 2, 2]} />*/}
            </Canvas>
        );
};

export default GalaxyCanvas;

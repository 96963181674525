import { BrowserRouter, useParams} from "react-router-dom";
import {About, Contact, Experience, Feedbacks, Hero, Navbar, Works, StarsCanvas, WhatsNext, Hobbies, TopNav} from './components'
import React, {Suspense} from "react";
import {SocialIcon} from "react-social-icons";
import Wheel from "./components/canvas/WheelNav";
import {ColorProvider} from "./components/ColorContext";

function App() {
  return (
      <>

          <ColorProvider>
          <BrowserRouter>
            <div className="relative z-0 bg-primary">
               {/*<TopNav/>*/}

              <div className="bg-hero-pattern bg-cover bg-no-repeat bg-center">
                <Navbar />
                <Hero />
                  {/*<Wheel />*/}
              </div>


              <About />
              <WhatsNext/>
              {/*<Family/>*/}
              <Experience />
              <Works />
              <Feedbacks />
              <Hobbies/>
              <div className="relative z-0">
                  <Contact />
                  <StarsCanvas />
              </div>



          </div>
      </BrowserRouter>
          </ColorProvider>
      </>

  );
}

export default App;

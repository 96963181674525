import {
    mobile,
    backend,
    creator,
    web,
    javascript,
    html,
    css,
    nodejs,
    mongodb,
    git,
    docker,
    gdcirclelogo,
    cengagelogofull,
    ubisoftwhite,
    hsnlogo,
    prestoqueryreport,
    gdforemployers,
    maltcampaignsuccess,
    acutracking,
    blankuser,
    creditsreel,
    whatstracked,
    novusdvm,
    solvelogo,
    dbxlogo,
    rigetti_logo,
    gai_logo,
    gdtechstack,
    gplogo,
    woodworking_highlight,
    yardwork,
    deck_renovation,
    guitar_lineup,
    outdoors,
    portfolio,
    snowflakecostusagereport,
    rosyeconomix_chart_openai,
    capstone_diagram,
    fldor
} from "../assets";
import background_fg_layer_exploring from "../assets/blue-galaxy-bg-fg-hiking.png";
import background_fg_layer_observing from "../assets/blue-galaxy-bg-fg-observing.png";
import background_fg_layer_investigating from "../assets/blue-galaxy-bg-fg-investigating.png";
import background_fg_layer_developing from "../assets/blue-galaxy-bg-fg-developing.png";
import background_fg_layer_living from "../assets/blue-galaxy-bg-fg-living.png";
import background_fg_layer_contemplating from "../assets/blue-galaxy-bg-fg-contemplating.png";

export const topLinks = [
    {
        id:"test1",
        title: "Fancy",
    },
    {
        id:"test2",
        title: "Clean",
    }
];
export const navLinks = [
    {
        id: "about",
        title: "About",
    },
    // {
    //     id: "family",
    //     title: 'Family',
    // },
    {
        id: "work",
        title: "Work",
    },
    {
        id: "hobbies",
        title: 'Hobbies',
    },
    {
        id: "contact",
        title: "Contact",
    },
];

const transitions = {
    exploring: {
        text: "exploring",
        img: background_fg_layer_exploring,
        color: 'to-emerald-600',
        colorHex: '#378963',
        highlightColorHex: '#53ba8a'
    },
    marveling: {
        text: "marveling at",
        img: background_fg_layer_observing,
        color: 'to-cyan-600',
        colorHex: '#58afdb',
        highlightColorHex: '#58afdb'
    },
    investigating: {
        text: "investigating",
        img: background_fg_layer_investigating,
        color: 'to-blue-600',
        colorHex: '#345ce2',
        highlightColorHex: '#345ce2'
    },
    developing: {
        text: "developing for",
        img: background_fg_layer_developing,
        color: 'to-violet-500',
        colorHex: '#7e5aef',
        highlightColorHex: '#7e5aef'
    },
    living: {
        text: "living in",
        img: background_fg_layer_living,
        color: 'to-fuchsia-600',
        colorHex: '#b452ce',
        highlightColorHex: '#b452ce'
    },
    contemplating: {
        text: "contemplating",
        img: background_fg_layer_contemplating,
        color: 'to-rose-600',
        colorHex: '#cd3e43',
        highlightColorHex: '#cd3e43'
    }
    // },
    // sparking: {
    //     text: "spark'ing across",
    //     img: background_fg_layer_sparking,
    //     color: 'to-red-600'
    // }
    // building: {
    //     text: "working on",
    //     img: background_fg_layer_building,
    // },
    // surfing: {
    //     text: "surfing",
    //     img: background_fg_layer_surfing,
    // },

}

const hobbies = [
    {
        name: "Woodworking",
        description:
            "I enjoy creating boxes, wood art, scroll sawing and otherwise building or testing out concepts I read about.  I am a huge fan of dovetails, and so utilize that technique in a lot of my work.",
        longdescription:"This is a long description",
        tags: [
            {
                name: "dovetails",
                color: "white-text-gradient",
            },
            {
                name: "lie-nielsen",
                color: "green-text-gradient",
            },
            {
                name: "bluespruce",
                color: "blue-text-gradient",
            },
        ],
        image: woodworking_highlight,
    },
    {
        name: "The great outdoors",
        description:
            "I enjoy getting out in nature, and going hiking, kayaking, canoeing, etc whenever we can fit it in.  Kim, I and the girls have hit various parks and rivers across Ohio.  We've also been known to take time to explore a new park, beach or area while on vacation.  Coming to a state or park near you!",
        longdescription:"This is a long description",
        tags: [

            {
                name: "hiking",
                color: "green-text-gradient",
            },
            {
                name: "canoeing",
                color: "orange-text-gradient",
            },
            {
                name: "nature",
                color: "green-text-gradient",
            },

        ],
        image: outdoors ,

    },
    {
        name: "Home Updates",
        description:
            "I am a big fan of doing things right, and so when it comes to our home I can be very particular.  I like to take my time to understand the code and requirements; and call in the pro's when its needed.  Otherwise, I generally tackle a lot of things myself.",
        longdescription:"This is a long description",
        tags: [

            {
                name: "festool",
                color: "green-text-gradient",
            },
            {
                name: "homerepair",
                color: "orange-text-gradient",
            },
            {
                name: "diy",
                color: "blue-text-gradient",
            },

        ],
        image: deck_renovation,

    },
    {
        name: "Yard Work",
        description:
            "I spend a lot of time in the yard, not always succeeding but always trying to make it look a little better.  So far in our new house, I've planted many willows, long grasses, shrubs and most recently planted Wisteria. ",
        longdescription:"This is a long description",
        tags: [
            {
                name: "yardwork",
                color: "green-text-gradient",
            },
            {
                name: "flowers",
                color: "pink-text-gradient",
            },
            {
                name: "gardens",
                color: "blue-text-gradient",
            },

        ],
        image: yardwork,

    },
    {
        name: "Guitar",
        description:
            "I'm not a great player, but I do enjoy the guitar.  I definitely have too many, but just can't seem to part with them.  I've had lessons in classical, blues, and rock; but mostly I  play to amuse myself and pass the time. I like Metallica, Guns-N-Roses (particularly Slash), AudioSlave and many other blues and rock groups.",
        tags: [
            {
                name: "PaulReedSmith",
                color: "white-text-gradient",
            },
            {
                name: "Fender",
                color: "green-text-gradient",
            },
            {
                name: "Gretch",
                color: "blue-text-gradient",
            },
        ],
        image: guitar_lineup,
    },
];
const services = [
    {
        title: "Leadership",
        icon: creator,
    },
    {
        title: "Data",
        icon: backend,
    },
    {
        title: "Web",
        icon: web,
    },
    {
        title: "Cloud",
        icon: mobile,
    },

];
const whatelse = [
    {
        name: "HTML 5",
        icon: html,
    },
    {
        name: "CSS 3",
        icon: css,
    },
    {
        name: "JavaScript",
        icon: javascript,
    },

    {
        name: "Node JS",
        icon: nodejs,
    },
    {
        name: "MongoDB",
        icon: mongodb,
    },
    {
        name: "git",
        icon: git,
    },
    {
        name: "docker",
        icon: docker,
    },
];
const technologies = [
    {
        name: "HTML 5",
        icon: html,
    },
    {
        name: "CSS 3",
        icon: css,
    },
    {
        name: "JavaScript",
        icon: javascript,
    },

    {
        name: "Node JS",
        icon: nodejs,
    },
    {
        name: "MongoDB",
        icon: mongodb,
    },
    {
        name: "git",
        icon: git,
    },
    {
        name: "docker",
        icon: docker,
    },
];

const experiences = [
    // {
    //     title: "P/T Staff Software Engineer",
    //     company_name: "Gable.ai",
    //     company_link: "https://www.gable.ai/",
    //     icon: gai_logo,
    //     iconBg: "#916bff",
    //     date: "September 2024 - Present",
    //     points: [
    //         "somewhere over the rainbow",
    //         "lorem ipsum dolor sit amet, consectetur adipiscing elit",
    //         "dorothy's red slipper shoes",
    //         "lorem ipsum dolor sit amet, consectetur adipiscing elit"
    //     ]
    // },
    {
        title: "Manager, AI Engineering",
        company_name: "Globalization Partners",
        company_link: "https://www.globalization-partners.com/gia/",
        icon: gplogo,
        iconBg: "#000000",
        date: "December 2024 - Preent",
        points: [
            "Coming Soon!"
            // "Developing strategic vision for AI-driven product development, with a focus on leveraging large language models (LLMs) to enhance G-P’s global workforce solutions and deliver impactful user experiences.",
            // "Building relationships with a cross-functional team of engineers, fostering a collaborative and innovative culture to drive technical excellence and deliver scalable solutions.",
            // "Collaborating with stakeholders across product, design, and engineering to prioritize impactful features that address customer needs, focusing on delivering intuitive, user-centric solutions.",

        ]

    },
    {
        title: "Lead Platform Engineer",
        company_name: "Rigetti Computing",
        company_link: "https://www.rigetti.com/",
        icon: rigetti_logo,
        iconBg: "#00B5AD",
        date: "August 2024 - November 2024",
        points: [
            "Resolved SSL issues affecting 100+ Palantir Foundry pipelines, restoring critical functionality.  This enabled data workflows essential to priority projects, and unlocked greater value from resources.",
            "Increased infrastructure reliability by leading Kubernetes stability improvements, reducing downtime and minimizing disruptions.",
            "Manage the remote development environment for QPU calibration software in Coder, including Docker-based environment setups.",
        ]
    },
    {
        title: "Sr Manager, Data Platform Services",
        company_name: "Glassdoor",
        company_link: "https://www.glassdoor.com",
        icon: gdcirclelogo,
        iconBg: "#0CAA41",
        date: "August 2021 - April 2023",
        points: [
            "Directed the Data Platform Services group in building the next generation of Glassdoor's data lake, utilizing EMR, EKS , Snowflake, Lambda, Glue, and other technologies for rapid data ingestion and analysis.",
            "Increased the headcount for Data Platform teams to strengthen the organization's technical capabilities and capacity.",
            // "Instituted data management best practices across Dimensional Modeling, ETL/ELT, and Data warehousing principles to ensure efficient and robust data wrangling.",
            "Identified cost savings measures and oversaw the migration and subsequent tuning efforts for Spark and Presto clusters, successfully transitioning from Qubole to Amazon EMR, saving the company $220,000 annually.",
            "Developed a comprehensive training curriculum for team and data consumer skill development, enhancing the organization's knowledge and competency used by over 100 engineers.",
            "Managed augmented staff to ensure alignment with project goals and successful delivery of big data solutions across data engineering, data science, and machine learning.",
            // "Led a cross functional group of three teams to manage Glassdoor big data platform, enabling hundreds of pipelines and  data consumers across the company",
            "Led cross team strategies to enhance monitoring and observability for query performance, costs, and data attribution increasing visibility into the company’s ROI for executive insights.",
            "Conducted CDP / CDI vendor analysis and led subsequent integration of Rudderstack and Amplitude, optimizing data handling and analytics capabilities for product level metrics.",
            // "Directed migrations and upgrades including HDP Hive and Spark to EMR & Snowflake, and Airflow from EC2 to Kubernetes",
        ],
    },
    {
        title: "Lead Data Operations Engineer",
        company_name: "Glassdoor",
        company_link: "https://www.glassdoor.com",
        icon: gdcirclelogo,
        iconBg: "#0CAA41",
        date: "November 2016 - August 2021",
        points: [
            "Architected and developed streaming solutions using AWS Kinesis/Lambda/SQS/S3, significantly reducing latency for event ingestion.  This service supports ingestion of over 300 Billion events per year, driving data and visualizations that provide insights and analysis for client brand performance as part of Glassdoor’s pay service business to business offering.",
            "Facilitated the conversion from manual deployments to Jenkins pipelines, enhancing and promoting continuous integration and deployment models, saving the company thousands of hours in engineering time.",
            "Supported AWS infrastructure and big data initiatives, contributing to the maintenance, automation and growth of the company's data infrastructure.",
            "Designed and implemented comprehensive monitoring and alerting across all Data Engineering services, ensuring optimal performance and proactive issue detection.",
            "Led efforts that enabled Machine Learning and Decision Science team autonomy; and provided support for model and reporting development efforts.",
            "Aligned DataEngineering organization with company standards, and coordinated integration with security and IT departments for authentication, security adherence and access level controls to data and services.",
        ],
    },
    {
        title: "Principal Architect - Analytics",
        company_name: "Ubisoft",
        company_link: "https://www.ubisoft.com",
        icon: ubisoftwhite,
        iconBg: "#4F6CA8",
        date: "December 2011 - November 2016",
        points: [
            "Directed and developed analytics integration processes for the North, Central and South American web properties focusing on game sites, ecommerce and client service portals",
            "Facilitated analytics for Mobile Game and Marketing groups in support of company initiatives. ",
            "Designed and developed tools for global management of all marketing campaigns and seamlessly integrated them into our data management processes providing metadata to our BI systems for mobile and marketing insights using BITools.  ",
            "Designed, developed and implemented tooling to allow PS4 Consoles to publish data via cloud services, allowing the capturing of analytics based on user behavior in the PSLive application. ",
            "Managed infrastructure, resources, and services in hosted (AWS) and internal (Montreal/Paris DC) environments and aligned with company policies (Follow the Sun support model)",
            "Directed operational and development improvements for Social Network Games, and guided software and infrastructure initiatives",
        ],
    },
    {
        title: "Sr Systems Engineer",
        company_name: "Cengage Learning",
        company_link: "https://www.cengage.com",
        icon: cengagelogofull,
        iconBg: "#fff",
        date: "July 2007 - December 2011",
        points: [
            "Responsible for the infrastructure, systems and services needed to support the company's eCommerce, Search and Library services offerings.  ",
            "Managed cross organization resources for project and timeline development and implementation efforts",
            "Subject matter expert for CengageBrain hosted eCommerce environment (F5/Apache/WebLogic/Solaris)",
            "Facilitated development initiatives for company branded product lines.",
            "Managed effort to migrate systems from external hosted vendor to utilizing CL’s Data Centers in Mason, OH and Independence, KY",
            "Supported subsidiary (Gale) product initiatives for the OMNI, Ocean, and Openweb product suites",
        ],
    },
    {
        title: "Sr Internet Operations Engineer",
        company_name: "Cornerstone Brands (A division of HSN)",
        company_link: "https://www.qurateretailgroup.com",
        icon: hsnlogo,
        iconBg: "#FFF",
        date: "May 2005 - July 2007",
        points: [
            "Managed technical direction for multiple B2C eCommerce sites under the Cornerstone Family of brands.",
            "Managed site and development enhancements; including feature additions, system and site architecture updates, etc",
            "SEO monitoring, adherence and strategy for maximum exposure of Branded product lines",
            "Subject matter expert for BroadVision / Tomcat / MarketLive / Omniture / Integration",
            "Technical liaison for subsidiary companies under the CBI umbrella",
        ],
    },
    {
        title: "Programmer / Systems Analyst",
        company_name: "Solve Interactive",
        company_link: "",
        icon: solvelogo,
        iconBg: "#FFF",
        date: "September 2001 - May 2005",
        points: [
            "Supported hosted websites for branded product lines; including IAMS Pet foods, Evenflo, Lexis-Nexis, Victoria Theater Company and many other area businesses",
            "Performed development on multiple projects in ASP.Net (VB/C#), ASP (classic), PHP, Perl, Java and Coldfusion",
            "Subject matter expert for Solaris, Linux, BSD, Windows and subsequent services being used (NFS, NIS, SMTP, DNS, HTTP, etc)",
            "Led several proof-of-concept initiatives for senior developers in PHP, Java, ASP, and ASP.Net",
        ],
    },
    {
        title: "Even gray beards start somewhere....",
        company_name: "Various",
        company_link: "",
        icon: fldor,
        iconBg: "#FFF",
        date: "June 1995 - September 2001",
        points: [
            "Worked in several positions while in college in Tallahassee, Florida; including the Florida Department of Revenue, Computer 101, Supernet, OPC and later worked for Home Computer Helpers in Dayton, Ohio",
            "Performed ISP related systems setup for business and client services (POP3, SMTP, DNS, FTP, HTTP)",
            "Subject matter expert for Windows, DOS, Linux",
            "Supported hosted websites for local businesses (Tallahassee, Fl)",
            "Supported local business network initiatives, using Novell, Lantastic, NT 3.51, SCO Unix, BSD Unix, and Linux",
            "Worked on several application and web development efforts in a junior capacity using languages such as Basic, FoxPro, Visual Basic (4-6), Pascal/Delphi, Java/J++ "
        ],
    },
];

const testimonials = [
    {
        testimonial:
            "It's rare to find such a strong knowledge base with such a willingness to teach and help.",
        name: "Chris Fiegel",
        designation: "Staff Data Engineer",
        company: "ProCore",
        image: blankuser,
    },
    {
        testimonial:
            "I was impressed with Jesse's knowledge and his tremendous willingness to be a team player.",
        name: "Birgit Hausmann",
        designation: "Sr Data Scientist, ML",
        company: "Glassdoor",
        image: blankuser,
    },
    {
        testimonial:
            "With Jess as our leader, I found we had the motivation, resources, and support needed to truly work to our full potential.",
        name: "Tony Marquez",
        designation: "Sr DevOps Engineer",
        company: "Glassdoor",
        image: blankuser,
    },

    {
        testimonial:
            "Jess is a wonderful engineering leader with a broad background in cloud and big data. His maturity in leading engineering initiatives to success were evident during multiple critical migrations at Glassdoor. I’ve been honored to learn from Jess’ depth of expertise in big data systems including Hadoop, Hive, Presto, Zeppelin, EMR, and much more.",
        name: "Zaki Siyaji",
        designation: "Lead Software Engineer",
        company: "Glassdoor",
        image: blankuser,
    },
    {
        testimonial:
            "Jesse oversaw the implementing of a new tracking system from end to end for the Sony PlayStation Live Item publishing tool for the Ubisoft CRM team. His ability to learn on the go the new Sony environment he had little involvement with prior to the project was of great value.",
        name: "Arch Kumar",
        designation: "Director, Data Science",
        company: "Sephora",
        image: blankuser,
    },
    {
        testimonial:
            "I worked with Jesse on the Analytics and Tracking group at Ubisoft. I can say he is the most skilled co-worker I have worked with. Jesse is very good at integrating analytic tools and metrics into complicated website ecosystem data structures and has a good understanding of the importance and value of the data.",
        name: "Jordane Blasco",
        designation: "Manager, Web Analytics Platform",
        company: "Ubisoft",
        image: blankuser,
    },
    {
        testimonial:
            "I worked with Jesse on many projects while at Solve Interactive and saw first hand his ability to solve complex problems and his passion for doing things right. Jesse is a talented technical resource and I'd recommend him for any organization.",
        name: "Mike Kompar",
        designation: "Director of Engineering",
        company: "Interpersonal Frequency",
        image: blankuser,
    },
    {
        testimonial:
            "Jesse possesses a rare combination of technical, communication, and business skills. Jesse used his technical abilities to come up with a wide variety of creative solutions to our technical problems. Jesse always had a cheerful and positive attitude and approach to work that was both infectious and refreshing.",
        name: "Casey DeGroot",
        designation: "Head of IT Americas",
        company: "GN Group",
        image: blankuser,
    },
    {
        testimonial:
            "Jesse has been a great engineering partner to work with, and has been crucial to helping build infrastructure to support Machine Learning tasks at Glassdoor. I would highly recommend him in any engineering team!",
        name: "Rolland He",
        designation: "Manager, ML Science",
        company: "Glassdoor",
        image: blankuser,
    },
    {
        testimonial:
            "Jesse is fantastically capable, reliable and multi-talented. He's also great to work with!",
        name: "Mark Lipson",
        designation: "CTO",
        company: "Opalytics, Inc",
        image: blankuser,
    },

    {
        testimonial:
            "Jesse has been one of my mentors since starting at Cengage Learning. He is always going Above and Beyond what is asked of him.",
        name: "Ronnie Hash",
        designation: "Sr Manager, M&A Security",
        company: "Salesforce",
        image: blankuser,
    },
    {
        testimonial:
            "I was very fortunate to work with Jesse. My projects would not have been a success without him. He is always driving to understand and master the technology, and he is totally dedicated to any project he works on.",
        name: "Chris Ellis",
        designation: "Project Manager II",
        company: "Zebra Technologies",
        image: blankuser,
    },

];
const projects = [
    {
        name: "DataExpert Presentation",
        description: "Presented my capstone project to the DataExpert.io November 2024 bootcamp!  Huge thanks to everyone that came out and participated!",
        longdescription: "It was a real honor to get invited back to present my capstone to the latest bootcamp!  Had a blast talking Data & AI and covering all the details I could pack into one hour.  I really enjoyed the process of fine tuning my presentation, getting to interact with the new students and further refining my thoughts and vision for this tool. Note since this is for the bootcamp, you must be a member to view the video.  Check the source link for my slide deck :-)",
        tags: [
            {
                name: "DataExpert.IO",
                color: "blue-text-gradient"
            },
            {
                name: "RosyEconomix",
                color: "red-text-gradient"
            }
        ],
        image: capstone_diagram,
        source_code_link: "https://www.jessecharbneau.com/downloads/v2_2_cohort_demo.rosy_economix.presentation.pdf",
        demo_site: "https://www.dataexpert.io/lesson/capstone-showcase-with-jesse-charbneau",
    },
    {
        name: "Rosy Economix",
        description: "My capstone project for the DataExpert.io v4 Course using ReactJS, FastAPI, Astronomer, DBT and PostgreSQL.  An awesome adventure! ",
        longdescription: "This project was a result of a 6 week journey I took with Zach Wilson's DataExpert.io course.  I incorporated a lot of the techniques (not all tho) I learned in class, and see quite a bit of potential with this sort of application.",
        tags: [
            {
                name: "reactjs",
                color: "white-text-gradient"
            },
            {
                name: "fastapi",
                color: "green-text-gradient"
            },
            {
                name: "openai",
                color: "red-text-gradient"
            },
            {
                name: "astronomer",
                color:"blue-text-gradient"
            },
            {
                name: "dbt-core",
                color: "orange-text-gradient"
            }
        ],
        image: rosyeconomix_chart_openai,
        source_code_link: "https://github.com/DataExpert-ZachWilson-V4/capstone-project-rosy_economix",
        demo_site: "https://capstone-demo.rosyeconomix.com/",
    },
    {
        name: "My portfolio site",
        description:
            "Yes, this very site is a favorite.  I am super impressed with some of the features I've explored in React and can see using it for a number of upcoming projects. ",
        longdescription:"This project has been a blast, and really gave me some much needed insight into the React Framework. Tinkering around with React has been quite interesting, and I'm not really missing the MVC pattern as much as I thought I might. I hope you enjoy my take on a portfolio website. ",
        tags: [
            {
                name: "reactjs",
                color: "white-text-gradient",
            },
            {
                name: "threejs",
                color: "green-text-gradient",
            },
            {
                name: "html5",
                color: "pink-text-gradient",
            },
            {
                name: "css",
                color: "blue-text-gradient",
            },
            {
                name: "portfolio",
                color: "orange-text-gradient",
            },

        ],
        image: portfolio,
        source_code_link: "",
        demo_site: "https://www.jessecharbneau.com/",
    },
    {
        name: "Glassdoor Eventbus",
        description:
            "Led the design and development of a cloud based service to allow for high speed / low latency ingestion of user, application, and service metrics supporting ingestion of over 300 Billion events per year. ",
        longdescription:"This was one of my favorite and most impactful projects while at Glassdoor.  This effort involved several technologies coming together in a SAAS service with-in the AWS Ecosystem.  My role was as the primary developer and product owner.  As I grew in my role with Glassdoor, I onboarded and collaborated with others to further enhance this service. This was an ongoing product for more than 4 years, with minimal downtime, exceptional value for cost and low-latency high-volume capabilities.  This project matured into a well running system that utilized Glue for automatic cleansing of events and delivery to our cleansed data area.",
        tags: [
            {
                name: "AWS",
                color: "green-text-gradient",
            },
            {
                name: "hive",
                color: "red-text-gradient",
            },
            {
                name: "airflow",
                color: "orange-text-gradient",
            },
            {
                name: "python",
                color: "blue-text-gradient",
            },
            {
                name: "java",
                color: "pink-text-gradient",
            },
        ],
        image: gdforemployers,
        source_code_link: "",
        demo_site: "",
    },{
        name: "Glassdoor Data Engineering Platform",
        description:
            "Led multi-year growth for Glassdoor's DataEngineering Platform incorporating Hadoop, Spark, Presto, Airflow and others in our quest to efficiently process petabytes of data.",
        longdescription:"By far one of the largest portions of my day to day was supporting Glassdoor's efforts to efficiently process petabytes of data.  On a daily basis we processed billions of rows, and terabytes of both structured and unstructured data, across over 250 pipelines; utilizing Hadoop MapReduce, Spark, Hive, Tez, Presto and Snowflake in a LakeHouse architecture.  Our teams delved into the trenches of big data for Decision Science, Machine Learning, and across the organization.  All in support of Glassdoor's mission of finding everyone a job and company they love!",
        tags: [
            {
                name: "Hive",
                color: "orange-text-gradient",
            },
            {
                name: "Airflow",
                color: "green-text-gradient",
            },
            {
                name: "Spark",
                color: "pink-text-gradient",
            },
            {
                name: "Presto",
                color: "blue-text-gradient",
            },
        ],
        image: gdtechstack,
        source_code_link: "",
        demo_site: "",
    },
    {
        name: "Snowflake Cost and Query Analysis",
        description:
            "Led effort to facilitate more intrinsic insight into costs associated with Snowflake, as well as query usage, cluster uptime and user query metrics",
        longdescription:"For this project, we needed to have more visibility into the costs associated with our Snowflake account.  We leveraged Airflow to create a dag that would execute every hour and retrieve metrics that we could then consume with Tableau.  This dashboard highlighted our costs per user, by query, by schema.  It also allowed us to drill into traffic patterns to see when our data warehouses were busiest.",
        tags: [
            {
                name: "snowflake",
                color: "blue-text-gradient",
            },
            {
                name: "tableau",
                color: "pink-text-gradient",
            },
            {
                name: "python",
                color: "orange-text-gradient",
            },
            {
                name: "sql",
                color: "white-text-gradient"
            }
        ],
        image: snowflakecostusagereport,
        source_code_link: "",
        demo_site: "",
    },

    {
        name: "Presto Query Analysis",
        description:
            "Led cross team effort to integrate Presto clusters with our event ingestion service (Eventbus), auto-cleanse data with AWS Glue and develop dashboards in Tableau.",
        longdescription:"This project was really awesome!  Using the code we had developed over previous iterations of our Presto implementations, we were able to update our custom Presto Event Listener to log all queries and events to our Eventbus.  When coupled with our updates that enabled automatic cleansing using Glue; we had all of our queries logged, with relevant metrics for consumption by our internal teams and dashboards in mere minutes.  \nAdditionally, these updates exposed that we regularly had over 60k queries per day (adhoc, api based backend calls, and decision science and machine learning jobs). ",
        tags: [
            {
                name: "presto",
                color: "blue-text-gradient",
            },
            {
                name: "eventbus",
                color: "white-text-gradient",
            },
            {
                name: "glue",
                color: "green-text-gradient",
            },
            {
                name: "tableau",
                color: "pink-text-gradient",
            },
            {
                name: "java",
                color: "orange-text-gradient",
            },
        ],
        image: prestoqueryreport,
        source_code_link: "",
        demo_site: "",
    },


    {
        name: "MALT",
        description:
            "MALT (or Marketing Analytics Link Tracker) was a tool I developed while working at Ubisoft that revolutionized their approach to Marketing Analytics.",
        longdescription:"What an amazing tool and initiative.  Ubisoft had a fantastic marketing group, with un-fantastic processes.  Each region would manage their campaigns, vendor relationships and marketing links using their own processes and Excel spreadsheets.  This resulted in a lot of discrepancy across the organization.  MALT corrected those issues and re-envisioned marketing efforts for Ubisoft at a global level.  This tooling allowed all marketing groups across the globe to effectively manage their campaigns, vendors and integrations.",
        tags: [
            {
                name: "angularjs",
                color: "white-text-gradient",
            },
            {
                name: "php",
                color: "green-text-gradient",
            },
            {
                name: "css",
                color: "pink-text-gradient",
            },
            {
                name: "vertica",
                color: "blue-text-gradient",
            },

        ],
        image: maltcampaignsuccess,
        source_code_link: "",
        demo_site: "",
    },
    {
        name: "What's Tracked?",
        description:
            "Ever look at a web page and wonder, \" What's being tracked?\".  This tool was developed to address that specific problem with Ubisoft's implementation of Adobe Analytics, Tag Commander and Google Tag Manager (GTM).",
        longdescription:"This tool allowed a single click to highlight all tracked elements, and selecting the elements would query to a REST api that utilized our Vertica data store; exposing metrics and other useful information.",
        tags: [
            {
                name: "javascript",
                color: "white-text-gradient",
            },
            {
                name: "php",
                color: "green-text-gradient",
            },
            {
                name: "html5",
                color: "pink-text-gradient",
            },
            {
                name: "vertica",
                color: "blue-text-gradient",
            },

        ],
        image: whatstracked,
        source_code_link: "",
        demo_site: "",
    },
    {
        name: "Credit where it's due",
        description:
            "This snippet was developed to thank & highlight the efforts of the many teams and individuals who helped the MALT tooling become successful.  ",
        longdescription:"Anyone who has worked with me would tell you that I am a people first colleague and so developed this feature as a way to show my appreciation to the folks who made MALT so successful.  This feature was developed using HTML, CSS and pure javascript; and was integrated into the Malt application and allowed for a movie credits style reel-to-reel effect. ",
        tags: [
            {
                name: "javascript",
                color: "white-text-gradient",
            },
            {
                name: "css",
                color: "green-text-gradient",
            },
            {
                name: "html",
                color: "pink-text-gradient",
            }
        ],
        image: creditsreel,
        source_code_link: "",
        demo_site: "https://credits.jcapphosting.com/",
    },
    {
        name: "Sony Live Area - Analytics integration",
        description:
            "This integration allowed tracking of user interactions on Playstation consoles and the associated marketing \"Tiles\" that are published by the CRM group. ",
        longdescription:"This integration used HTML / Javascript / PSN API to allow tracking events to be loaded directly to S3 via AWS Firehose (using AWS JS SDK). This would allow staging the data for inclusion in many different backends as needed.",
        tags: [
            {
                name: "javascript",
                color: "white-text-gradient",
            },
            {
                name: "kinesis",
                color: "green-text-gradient",
            },
            {
                name: "python",
                color: "pink-text-gradient",
            },
            {
                name: "vertica",
                color: "blue-text-gradient",
            },

        ],
        image: acutracking,
        source_code_link: "",
        demo_site: "",
    },

    {
        name: "A touchscreen vending machine",
        description: "Before the iPad, the touchscreen capabilities were already proving addictive.  This project was a collaboration with a local (Cincinnati) hardware engineer.  ",
        longdescription:"While the hardware engineer reverse engineered the CocaCola vending machine circuit, I developed a multi touchscreen application and gave it similar functionality to an iPhone. The contracting company has posted the full video.",
        tags: [
            {
                name: "linux",
                color: "white-text-gradient",
            },
            {
                name: "C",
                color: "green-text-gradient",
            },
            {
                name: "EFL",
                color: "pink-text-gradient",
            },
            {
                name: "touchscreen",
                color: "blue-text-gradient",
            },
            {
                name: "hardware",
                color: "orange-text-gradient",
            },

        ],
        image: novusdvm,
        source_code_link: "https://github.com/",
        demo_site: "https://www.youtube.com/watch?v=hEgVrcxIguk&t=4s",
    },
];

export { services, technologies, experiences, testimonials, projects, hobbies, transitions };
import { motion } from 'framer-motion';
import { styles } from '../styles';
import { SectionWrapper } from '../hoc'
import React from "react";

const WhatsNext = () => {
  return (
    <>
    <div className="rounded-[20px] -mt-14">
        <div className={`${styles.padding} min-h-[300px] `}>
            <motion.div>
                <p className={`${styles.sectionSubText} jcblue-text`}>The Future is Now</p>
                <h2 className={styles.sectionHeadText}>Work</h2>
            </motion.div>
        </div>
        <div
            className={`${styles.paddingX} -mt-20 pb-14 flex flex-wrap gap-7 min-h-screen `}
        >
            <motion.p
                className="mt-4 text-secondary text-[17px] max-w-6xl leading-[30px]"
            >

                {/*What do I want to do when I grow up?   I think of this question often, and I have to say I'd like to keep moving forward working with teams across organizations to facilitate application, web and data analytics. There are so many opportunities in the data engineering landscape; from platform development and management, to high speed ingestion, to machine learning and AI initiatives to development maturity using continuous integration and deployment models.  It is a fantastic time to be in the data space!  Every vertical in data engineering is evolving, and sometimes at break neck speed.*/}

                {/*<p className="text-[17px] text-secondary w-full mt-5">*/}
                {/*     providing self-service capabilities for engineers, business leaders and teams needing access to data and analytics services.*/}
                {/*</p>*/}
                <span className="text-[17px] text-secondary w-full mt-5">
              {/*I am interested in engineering leadership roles in the data space.  I would welcome opportunities where I could leverage my skills &amp; experience while increasing my knowledge and capabilities with Spark and Databricks.*/}
                    {/*     </span><br/><br/>*/}
                    {/*<span className="text-[17px] text-secondary w-full mt-5">*/}
                    {/*    With my background managing teams, leading large scale projects, designing systems and working through and successfully completing complex initiatives; I am able to offer many insights and skills for businesses looking to leverage cloud technologies in the data &amp; analytics spaces.*/}
                    I am passionate about engineering leadership roles in the AI, data, and analytics space. With extensive experience managing teams, leading large-scale projects, designing scalable systems, and delivering complex initiatives, I bring valuable insights and skills to organizations looking to harness the power of cloud technologies, AI-driven solutions, and advanced analytics.
                </span><br/><br/>
                <span className="text-[17px] text-secondary w-full mt-5">
                    I thrive when empowering others—recruiting and mentoring talented engineers, fostering skill development, and guiding teams to achieve their career goals while advancing technology initiatives. I excel at building systems and processes that operate at scale, ensuring reliability, efficiency, and impact.
                 </span><br/><br/>
                <span className="text-[17px] text-secondary w-full mt-5">
                    Collaboration is at the heart of my leadership style. I enjoy working with cross-functional teams to understand their needs, align goals, and partner with engineers and analysts to drive innovation and deliver actionable insights. Whether it’s designing robust data structures, architecting AI solutions, or optimizing workflows, I am dedicated to creating systems that transform data into meaningful business and consumer outcomes.
                </span>

                <br/><br/>
                <span className="mt-8  w-full">Learn more about my work related ...</span>
                <span className="mt-2 inline-flex text-center w-full ">

               <span className="w-1/3">
                  <a href="#experience" className="cursor-pointer hover:text-blue-500">
                      <span
                          className="w-full h-full justify-center inline-flex p-2 border-tertiary hover:bg-tertiary bg-primary">
                     Experience
                      </span>
                  </a>
              </span>
              <span className="w-1/3">
                 <a href="#projects" className="cursor-pointer hover:text-blue-500 ">

                     <span
                         className="w-full h-full justify-center inline-flex p-2 border-tertiary hover:bg-tertiary bg-primary">
                      Projects
                     </span></a>
              </span>

              <span className="w-1/3">
                  <a href="#testimonials" className="cursor-pointer hover:text-blue-500">
                      <span className="w-full h-full justify-center inline-flex p-2 hover:bg-tertiary bg-primary">
                     Testimonials
                      </span>
                  </a>
              </span>
          </span>
                <div
                    className="cursor-pointer w-full flex justify-center items-center mt-5 pt-5 ">
                    <a href="#experience" className="cursor-pointer works_chevron_container">
                        <div className="works_chevron_container cursor-pointer ">
                            <div className="works_chevron cursor-pointer"></div>
                            <div className="works_chevron cursor-pointer"></div>
                            <div className="works_chevron cursor-pointer"></div>
                        </div>
                    </a>
                </div>
            </motion.p>

            <style>
                {`
                    .about_chevron:before,
                    .about_chevron:after {
                        border-left: .01em solid #b452ce';
                        border-bottom:  .5px solid #b452ce;
                        border-top: .5px solid #b452ce;
                        border-right: .01em solid #b452ce;
                        background: #b452ce;
                    }`}
            </style>
    </div>
    </div>
</>
)
}

export default SectionWrapper(WhatsNext, "work")
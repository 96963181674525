import React, { useContext, useEffect } from "react";
import {ColorContext} from "./ColorContext";

import CrossfadeImage from "../components/CrossfadeImage";
import {GalaxyCanvas} from './canvas';

import { styles } from '../styles';
import herobg from '../assets/blue-galaxy-bg.jpg';
import heroly1 from '../assets/blue-galaxy-bg-fg.png';
import TranslucentEyes from "./TranslucentEyes"; // Add the overlay
import {transitions} from "../constants";


const Hero = () => {
    const [imgSrc, setImgSrc] = React.useState(
        transitions.exploring.img
    );

    const [displayText, setText] = React.useState(
        transitions.exploring.text
    );

    const [displayColor, setTextColor] = React.useState(
        transitions.exploring.color
    );

    const { colorHex, setColorHex } = useContext(ColorContext);
    // const [colorHex, setColorHex] = React.useState(
    //     transitions.exploring.colorHex
    // );

    const [highlightColorHex, setHighlightColorHex] = React.useState(
        transitions.exploring.highlightColorHex
    )
    const [transitionKey, setTransitionKey] = React.useState(
        "exploring"
    );
    const [timeoutvalue, setTimeoutValue] = React.useState(
        10000
    )
    const changeImg = (imgUrl) => {
        setImgSrc(imgUrl)
    };

    const changeText = (text) => {

      setText(text)
    };

    const changeTextColor = (textColor) => {
      setTextColor(textColor)
    };
    const changeColorHex = (colorHex) => {
      setColorHex(colorHex)
    };
    const changeHighlightColorHex = (highlightColorHex) => {
      setHighlightColorHex(highlightColorHex)
    };
    const changeTimeoutValue = (timeoutvalue) => {
        setTimeoutValue(timeoutvalue)
    }
    // const [index, setIndex] = React.useState(0);
    React.useEffect(() => {
        const changeTransitionKey = (index) => {
            setTransitionKey(index)
        };

        const findNext = (key, obj) => {
            let keys = Object.keys(obj);
            return (keys[(keys.indexOf(key) + 1) % keys.length])
        }

        const intervalId = setInterval(
            () => {
                // let changed = false;
                let currentDisplay = {};
                let nextKey = findNext(transitionKey, transitions);
                changeTransitionKey(nextKey);

                currentDisplay = transitions[transitionKey];

                changeImg(currentDisplay.img);
                // changeText('');
                changeText(currentDisplay.text);
                changeTextColor(currentDisplay.color);
                changeColorHex(currentDisplay.colorHex);
                changeHighlightColorHex(currentDisplay.highlightColorHex);
            },
            timeoutvalue, // every X milliseconds
        );

        return () => {
            clearTimeout(intervalId);
        }
    }, [transitionKey, imgSrc, displayText, displayColor]);

    // if (isMobile) {
    return (
            <section className="relative w-full h-screen  mx-auto mt-2 mb-5 align-bottom bottom-0">
                {/*<TranslucentEyes displayColor={colorHex} />*/}
                {/* Another set of translucent eyes with a lower zIndex */}


                <div className="flex overflow-hidden absolute w-full h-screen opacity-100 rounded-xl z-[0]  m-0 p-0 align-bottom bottom-0 " style={{ textAlign:"center", verticalAlign:"middle", display:"inline-block"}}>

                    <img src={herobg} alt={displayText}  style={{ zIndex:10,padding:0,margin:0, marginLeft:0 }} className="w-screen h-screen object-fill hero-rotate transform-gpu "/>

                </div>
                <div className="w-full h-full opacity-100 flex rounded-xl z-[-1] inline-block">
                    <div className="relative w-full flex z-0 mt-10 align-bottom bottom-0">
                        <div className="flex flex-col transition-transform z-20 absolute opacity-100 text-white w-full text-center align-bottom bottom-0 border-0 ">
                            <CrossfadeImage duration={2500} timingFunction={"ease"} src={imgSrc} alt="" style={{ zIndex:20,padding:0,margin:0, marginLeft:0, bottom:0, width:'100%' }} className="align-bottom absolute w-screen h-screen object-fill transform-gpu fade-in-herobg">
                                <div className="flex overflow-hidden transition-transform z-18 absolute opacity-100 text-white w-full text-center h-auto border-0 align-bottom ">
                                    <img src={heroly1} alt="" style={{ zIndex:18,padding:0,marginBottom:0, marginLeft:0, width:'100%' }} className="align-bottom absolute w-screen h-screen object-fill"/>
                                </div>
                            </CrossfadeImage>
                            {/*<div className=" bg-black relative flex flex-grow-0 w-screen h-auto w-full object-fill">&nbsp;<br/><br/>hello</div>*/}
                        </div>
                        <div className="flex pb-12 ml-1 w-full flex z-10 text-center h-screen mt-10">
                            <GalaxyCanvas displayColor={` ${colorHex} `} className={`z-10 absolute transform-gpu ml-1`} />
                        </div>
                        <div className="absolute w-full h-full z-15 inline-block">
                            <TranslucentEyes
                                displayColor={colorHex}
                                // position={{ x: 800, y: 240 }}
                                scale={0.6}
                                rotation={15}
                                opacity={0.5}
                                glowIntensity={50}
                                align="bottom-right"
                                flip={false}
                                zIndex={50} // Lower zIndex
                            />
                        </div>
                    </div>

                </div>
                <div className={`${styles.paddingX} border-0 absolute inset-0 top-[80px] max-w-7xl mx-auto flex flex-row items-start gap-5 opacity-100 z-[12]`}>
                    <div className="z-2 bg-black-200 rounded-xl pt-2 pb-2 pl-6 w-screen bg-opacity-60 bg-gradient-to-b  ">
                        <h1 className={`${styles.heroHeadText} text-white`}>Hi, I'm <span className={`flex-none font-bold z-1 text-transparent bg-clip-text bg-gradient-to-t from-white-100  pb-2 inline-flex ${displayColor}`}>Jess</span> </h1>
                        <p className={`${styles.heroSubText} mt-2 text-white-100 inline-block `}>
                            A technology enthusiast&nbsp;
                            <span
                                className={`flex-none font-bold z-1 text-transparent bg-clip-text bg-gradient-to-t from-white-100 pb-2 inline-flex ${displayColor}`}>
                                {displayText}

                          </span>
                            {/*&nbsp;the big data universe.*/}
                            &nbsp;the&nbsp;
                            {/*    <span className="relative inline-block">*/}
                            {/*  <span>big data</span>*/}
                            {/*  <span*/}
                            {/*      className={`absolute flex-none text-transparent bg-clip-text bg-gradient-to-t from-white-100 pb-2 ${displayColor}`}*/}
                            {/*      style={{*/}
                            {/*          top: '-55px',*/}
                            {/*          left: '133px',*/}
                            {/*          minWidth: '200px',*/}
                            {/*          transform: 'translateX(-50%)',*/}
                            {/*          // border: '1px solid #ffffff',*/}
                            {/*          paddingTop: '10px',*/}
                            {/*      }}*/}
                            {/*  >*/}
                            {/*  </span>*/}
                            {/*</span>*/}
                            <div className="relative inline-block">
                                <span>big data</span>
                                <span
                                    className={`absolute flex-none font-bold text-transparent bg-clip-text bg-gradient-to-r from-white-100 pb-2 ${displayColor}`}
                                    style={{
                                        top: "-45px",
                                        left: "133px",
                                        minWidth: "200px",
                                        transform: "translateX(-50%)"
                                    }}
                                >
        <span
            className={`font-handwriting`}
            style={{
                display: "inline-block",
                whiteSpace: "nowrap",
                textAlign: "left",
                stroke: '1px white',
            }}
        >
            & Generative-ai
        </span>
        <span
            className={`block`}
            style={{
                fontSize: ".8em",
                lineHeight: "1em",
                textAlign: "center"
            }}
        >
            V
        </span>
    </span>
                            </div>
                            &nbsp;universe.
                            <span
                                className={`font-handwriting flex-none font-bold text-transparent bg-clip-text bg-gradient-to-r from-white-100 pb-2 ${displayColor}`}
                                style={{
                                    fontSize: "30pt",
                                    whiteSpace: "nowrap",
                                    textAlign: "left",
                                    stroke: '1px #ffffff',
                                }}
                            >&apos;s</span>
                        </p>
                    </div>
                </div>
                <div
                    className="cursor-pointer absolute xs:bottom-10 bottom-32 w-full flex justify-center items-center mt-5 pt-5 ">
                <a href="#about" className="cursor-pointer container">
                        <div className="chevron_container cursor-pointer ">
                            <div className="chevron cursor-pointer"></div>
                            <div className="chevron cursor-pointer"></div>
                            <div className="chevron cursor-pointer"></div>
                        </div>
                    </a>
                </div>
                <style>
                    {`
                    .sparkle { 
                        background: ${highlightColorHex};
                    }
                    .chevron:before,
                    .chevron:after {
                        border-left: .01em solid ${highlightColorHex};
                        border-bottom:  .5px solid ${highlightColorHex};
                        border-top: .5px solid ${highlightColorHex};
                        border-right: .01em solid ${highlightColorHex};
                        background:${colorHex};
                    }`}
                </style>
            </section>
        )
}

export default Hero
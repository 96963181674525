// ColorContext.js
import React, { createContext, useState } from "react";

// Create the context
export const ColorContext = createContext();

// Provider component
export const ColorProvider = ({ children }) => {
    const [colorHex, setColorHex] = useState("#ffffff"); // Default color

    return (
        <ColorContext.Provider value={{ colorHex, setColorHex }}>
            {children}
        </ColorContext.Provider>
    );
};

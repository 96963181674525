import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { styles } from '../styles';
import { navLinks } from '../constants';
import { logo, menu, close } from '../assets';
import { RiDownloadCloud2Line } from "react-icons/ri";


const Navbar = () => {
  const [active, setActive] = useState('');
  const [toggle, setToggle] = useState(false);
  const buttonStyling = `text-[14px] flex space-x-3 mr-2 font-semibold bg-gradient-to-r from-slate-500 via-slate-700 to-slate-900  text-gray-100 rounded-md ring-2 ring-slate-400 px-6 py-2 hover:bg-white hover:text-white hover:ring-slate-300 mx-8 shadow-lg shadow-slate-800/100`

  return (
    <nav className={`${styles.paddingX} w-full flex items-center py-5 fixed top-0 z-40 bg-primary`}>
      <div className="w-full flex justify-between items-center max-w-7xl mx-auto">
        <Link to="/"
              className="flex items-center gap-2 "
              onClick={() => {
                setActive("");
                window.scrollTo(0,0)

              }}>
              <img src={logo} alt="logo" className="w-9 h9 object-contain" />
              <p className="text-white text-[18px] font-bold cursor-pointer flex">
                  Jesse Charbneau&nbsp;<span className="sm:block hidden md:hidden">| Father | Husband | Technologist</span>
              </p>

        </Link>
        <ul className="list-none hidden sm:flex flex-row gap-10">
            {navLinks.map((Link) => (
                <li
                    key={Link.id}
                    className={`${
                        active===Link.title
                            ? "text-white"
                            : "jcblue-text"
                    } hover:text-white text-[16px] font-medium cursor-pointer `}
                    onClick={() => setActive(Link.title)}
                >
                    <a className="" href={`#${Link.id}`}>{Link.title}</a>
                </li>
            ))}
            <li className="jcblue-text hover:text-white text-[16px] font-medium cursor-pointer inline-flex rounded-none flex-nowrap">
                <Link to="/downloads/JesseCharbneau_latest_ic.cv.pdf" target="_blank">CV</Link>
            </li>
            {/*<li className="hover:text-white text-[16px] font-medium cursor-pointer inline-flex rounded-none">*/}
            {/*    <Link to="/downloads/JesseCharbneau_latest_ic.cv.pdf" target="_blank" download>*/}
            {/*        <button type="submit" className={buttonStyling}>*/}
            {/*            <p>Download CV</p><RiDownloadCloud2Line  size="1rem" />*/}
            {/*        </button>*/}
            {/*    </Link>*/}
            {/*        /!*<button type="submit" className={buttonStyling}></button>*!/*/}
            {/*        /!*<SocialIcon bgColor="#4A80FF" fgColor="#ffffff" className="max-w-[24px] max-h-[24px] rounded-none" network="linkedin" url="https://www.linkedin.com/in/jcharbneau/" target="_blank" />*!/*/}
            {/*        /!*<SocialIcon bgColor="#4A80FF" fgColor="#ffffff" className="max-w-[24px] max-h-[24px] rounded-none" network="download_cv" url="https://www.jessecharbneau.com/downloads/JesseCharbneau_latest_ic.cv.pdf" target="_blank" />*!/*/}

            {/*</li>*/}
        </ul>
          <div className="sm:hidden flex flex-1 justify-end items-center">
            <img src={toggle ? close : menu}
                 alt="menu"
                 className="w-[28px] h-[28px] object-contain cursor-pointer "
                 onClick={() => setToggle(!toggle)} />
            <div className={`${!toggle ? 'hidden' : 'flex'} p-6 black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] z-10 rounded-xl`}>
                <ul className="list-none flex justify-end items-start flex-col gap-4">
                    {navLinks.map((Link) => (
                        <li
                            key={Link.id}
                            className={`${
                                active===Link.title
                                    ? "text-white"
                                    : "jcblue-text"
                            } font-poppins font-medium cursor-pointer text-[16px] jcblue-gradient `}
                            onClick={() => {
                                setToggle(!toggle);
                                setActive(Link.title);
                            }}
                        >
                            <a href={`#${Link.id}`}>{Link.title}</a>
                        </li>
                    ))}
                </ul>
            </div>

        </div>
      </div>
    </nav>
  )
}

export default Navbar
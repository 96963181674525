import React from "react";
import EyeOverlay from "../assets/eyes.svg"; // Add your SVG file for the eyes
import CrossfadeImage from "../components/CrossfadeImage"; // Reuse the CrossfadeImage component

const TranslucentEyes = ({
                             displayColor = "#ffffff", // Default glow color
                             position = { x: 550, y: 350 }, // Default position (x, y)
                             scale = 0.4, // Default scale
                             rotation = 10, // Default rotation in degrees
                             opacity = 0.3, // Default opacity
                             glowIntensity = 120, // Default glow intensity
                             zIndex = 11, // Default zIndex
                             flip = false, // Whether to flip the eyes horizontally
                             align = "custom", // Positioning option: 'custom', 'top-right', etc.
                         }) => {
    // Compute position for predefined alignments
    const alignmentStyles = {
        "top-right": {
            top: "0px",
            right: "0px",
            transform: `scale(${scale}) rotate(${rotation}deg) ${flip ? "scaleX(-1)" : ""}`,
            transformOrigin: "center",
        },
        "bottom-right": {
            bottom: "-200px",
            right: "50px",
            transform: `scale(${scale}) rotate(${rotation}deg) ${flip ? "scaleX(-1)" : ""}`,
            transformOrigin: "center",
        },
        custom: {
            transform: `rotate(${rotation}deg) translateX(${position.x}px) translateY(${position.y}px) scale(${scale}) ${
                flip ? "scaleX(-1)" : ""
            }`,
            transformOrigin: "center",
        },
    };

    const alignmentStyle = align === "custom" ? alignmentStyles.custom : alignmentStyles[align];

    return (
        <div
            className="absolute pointer-events-none glowing-eye"
            style={{
                zIndex, // Configurable zIndex
                opacity, // Configurable opacity
                position: align === "custom" ? "absolute" : "fixed", // Fixed for predefined positions like top-right
                ...alignmentStyle, // Apply computed alignment styles
                "--glow-color": displayColor, // CSS variable for glow color
            }}
        >
            <CrossfadeImage
                duration={2000} // Adjust duration to match your fade timing
                timingFunction="ease"
                src={EyeOverlay}
                alt="Translucent Eyes"
                style={{
                    filter: `drop-shadow(0px 0px ${glowIntensity}px ${displayColor})`, // Configurable glow intensity
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                }}
                className="w-full h-full glowing-eye"
            />
        </div>
    );
};

export default TranslucentEyes;

import React from "react";
import { Tilt } from 'react-tilt';
import { SectionWrapper } from '../hoc';
import { motion } from 'framer-motion';
import { styles } from '../styles';
import { projects } from '../constants';
import ReactCardFlip from "react-card-flip";


const ProjectCard = ({ index, name, description, longdescription, tags, image, source_code_link, demo_site }) => {
    const [ isFlipped,setIsFlipped] = React.useState(
      false
    );
    const handleClick = () => {
        setIsFlipped((current) => !current)
    };


  return (

      <motion.div className="flex h-[520px] cursor-pointer" onClick={handleClick}>
          {/*<Tilt*/}
          {/*    options={{*/}
          {/*        max: 8,*/}
          {/*        scale: 1,*/}
          {/*        speed: 350*/}
          {/*    }}*/}
          {/*    className="bg-tertiary p-5 rounded-2xl sm:w-[320px] w-full jcblue-gradient cursor-pointer"*/}
          {/*>*/}

          <ReactCardFlip isFlipped={isFlipped}>

                  {/* front */}
              <front>
                  <div className="bg-tertiary p-5 rounded-2xl w-[320px] h-[525px] jcblue-gradient cursor-pointer">
                      <div className="relative w-full h-[230px]">
                          <img src={image} alt={name} className="w-full h-full object-cover-rounded-2xl rounded-md"/>
                          <div className="absolute inset-0 flex justify-end m-3 card-img_hover">
                          </div>
                      </div>
                      <div className="mt-5">
                          <h3 className="text-white font-bold text-[24px]">{name}</h3>
                          <p className="mt-2 text-secondary text-[14px]">{description}</p>
                      </div>
                      <div className="mt-4 flex flex-wrap gap-2">
                          {tags.map((tag) => (
                              <p key={tag.name} className={`text-[14px] ${tag.color}`}>
                                  #{tag.name}
                              </p>
                          ))}
                      </div>

                      {/*<div className="inset-x-0 h-full bottom-0 text-[14px] text-blue-900 hover:text-blue-400 mt-4 mb-1 flex justify-center">*/}
                      {/*    <p className="inset-x-0 bottom-0 h-16">Click for details</p>*/}
                      {/*</div>*/}
                  </div>
              </front>
              {/* back */}
              <back>
                  <div className="bg-tertiary p-5 rounded-2xl w-[320px] h-[525px] jcblue-gradient cursor-pointer">
                      <div>
                          <h3 className="text-white font-bold text-[24px]">{name}</h3>
                          <p className="mt-2 text-secondary text-[14px]">{longdescription}</p>
                          <p className="mt-2 text-secondary text-[14px]"><a href={demo_site} target="_blank">{demo_site}</a></p>
                      </div>
                  </div>
              </back>
          </ReactCardFlip>


          {/*</Tilt>*/
          }
      </motion.div>
)
}
const Works = () => {
    return (
    <>
        <div className="rounded-[20px] -mt-14 ">
            <div className={`${styles.padding} min-h-[300px] `}>
      <motion.div>
        <p className={`${styles.sectionSubText} jcblue-text`}>Results Matter</p>
        <h2 className={styles.sectionHeadText}>Projects</h2>
      </motion.div>
            </div>
            <div
                className={`${styles.paddingX} -mt-28 pb-14 flex flex-wrap gap-7 `}
            >
      <div className="w-full flex">
        <motion.p

          className="mt-1 text-secondary text-[17px] max-w-7xl leading-[30px]">
            <p>The following projects are a few of my favorites and showcase my skills and experience across a wide range of industries, disciplines and roles.  Each project includes a brief description and the technologies that were selected. </p>
            <br/>For a more up to date and complete list of projects, please see my&nbsp;
            <u>
                <a
                    rel="noreferrer"
                    className="cursor-pointer hover:text-white"
                    target="_blank"
                    href="https://www.linkedin.com/in/jcharbneau/details/projects/">
                    Projects Listing
                </a>
            </u> on LinkedIn.
            <br/><br/>
            <span className="text-center"><p>Click the card to see more details!</p></span>

        </motion.p>
      </div>
      <div className="mt-4 flex flex-wrap gap-7">
        { projects.map((project,index) => (
            <ProjectCard
                key={`project-${index}`}
                index={index}
                {...project}
            />
        ))}

      </div></div></div>
        {/*</div>*/}
    </>
  )
}

export default SectionWrapper(Works, "projects");

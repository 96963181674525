import React from "react";
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { motion } from "framer-motion";

import "react-vertical-timeline-component/style.min.css";

import { styles } from "../styles";
import { experiences } from "../constants";
import { SectionWrapper } from "../hoc";
const ExperienceCard = ({experience}) => (
  <VerticalTimelineElement
    contentStyle={{background: '#182C5C', color:'#fff'}}
    contentArrowStyle={{ borderRight: '7px solid #232631'}}
    date={experience.date}
    iconStyle={{background:experience.iconBg}}
    icon={
        <div className="flex justify-center items-center w-full h-full">
            {experience.company_link && experience.company_link.trim() !== "" ? (
                <a
                    href={experience.company_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <img
                        src={experience.icon}
                        alt={experience.company_name}
                        className="w-[60%] h-[60%] object-contain"
                    />
                </a>
            ) : (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        cursor: "not-allowed",
                    }}
                    title="URL not available"
                >
                    <img
                        src={experience.icon}
                        alt={experience.company_name}
                        className="w-[60%] h-[60%] object-contain opacity-50"
                    />
                </div>
            )}
        </div>

    }
  >
      <div>
          <h3 className="text-white text-[24px] font-bold">{experience.title}</h3>
          <p className="text-secondary text-[16px] font-semibold blue-text-gradient"
             style={{margin: 0}}>{experience.company_name}</p>
      </div>
      <ul className="mt-5 list-disc ml-5 space-y-2">
          {experience.points.map((point, index) => (
              <li
                  key={`experience-point-${index}`}
                  className="text-white-100 text-[14px] pl-1 tracking-wider"
              >
                  {point}
              </li>
          ))}
      </ul>
  </VerticalTimelineElement>
)
const Experience = () => {
    return (
        <>
            <div
                className="bg-black-100 rounded-[20px] -mt-10"
            >

                <div className={`${styles.padding} bg-tertiary rounded-2xl`}>
                    <motion.div>
                        <p className={`${styles.sectionSubText} opacity-100 jcblue-text`}>
                            My accomplishments
                        </p>
                        <h2 className={`${styles.sectionHeadText} opacity-100`}>
                            Experience
                        </h2>
                        <p className="text-[17px] text-secondary w-full px-14">
                            I am a seasoned technology leader with over 25 years of experience driving innovation in
                            infrastructure, software development, and AI-powered solutions. My expertise spans big data
                            analytics, scalable web services, and systems automation, empowering organizations to
                            achieve transformative results. </p>

                        <p className="text-[17px] text-secondary w-full px-14 mt-5">
                            Throughout my career, I have excelled in senior, architect, and management roles, leading
                            cross-functional teams to deliver large-scale initiatives. I have overseen high-traffic
                            eCommerce platforms, complex search and indexing systems, global data environments, and
                            mission-critical infrastructure. With a passion for leveraging cutting-edge technologies
                            like cloud platforms, AI, and automation, I thrive in creating high-performing, reliable,
                            and impactful systems.

                        </p>
                        <p className="text-[17px] text-secondary w-full px-14 mt-5">

                            In my current role, I am focused on building and leading teams to develop innovative
                            AI-driven products that solve complex global challenges.
                        </p>
                    </motion.div>
                    <div className='mt-20 flex flex-col'>
                        <VerticalTimeline>
                {experiences.map((experience, index) => (
                    <ExperienceCard
                        key={`experience-${index}`}
                        experience={experience}
                    />
                ))}
            </VerticalTimeline>
        </div>

            </div>

        </div>
    </>
  );
};

export default SectionWrapper (Experience, "experience")